import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "./../../../environments/environment";
import { catchError } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class LoanService {
	// private esBaseUrl = environment.urls.elastic.base;
	private esBaseUrl = environment.urls.elastic.lambda;
	private apiBaseUrl = environment.urls.api.base;
	private iceUrl = environment.urls.api.ice_url;
	private ovsUrl = environment.urls.api.ovs_url;
	private lmsUrl = environment.urls.api.lms_url;

	constructor(private http: HttpClient) {}

	// getLoan(loan_id: string): Observable<any> {
	// 	/* return this.http.get(
	// 		this.esBaseUrl + environment.urls.elastic.indices.loans + '/_search?' +
	// 		'q=loan_uid:' + loan_id
	// 	); */
	// 	let body = {
	// 		index: environment.urls.elastic.indices.loans,
	// 		body: {
	// 			query: {
	// 				match_phrase: { loan_uid: loan_id },
	// 			},
	// 			sort: [],
	// 			aggs: {
	// 				total_count: {
	// 					value_count: {
	// 						field: '_id',
	// 					},
	// 				},
	// 			},
	// 			user: {},
	// 		},
	// 		_source: [],
	// 		size: 10000,
	// 		from: 0,
	// 		filterPath: [],
	// 		sort: [],
	// 	};
	// 	return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
	// }

	getLoan(loan_uid: string): Observable<any> {
		let body = {
			loan_uid: loan_uid,
		};
		return this.http.post(this.apiBaseUrl + "elastic/loan_details", body, this.esHeaders());
	}

	getSanctionLoanDetails(payload): Observable<any> {
		return this.http.post(this.apiBaseUrl + "elastic/sanction/loan_details", payload, this.esHeaders());
	}

	saveCustomersDetails(payload): Observable<any> {
		return this.http.patch(this.apiBaseUrl + "elastic/sanction/update_kyc", payload, this.esHeaders());
	}

	saveDistributorDetail(payload): Observable<any> {
		return this.http.post(this.apiBaseUrl + "elastic/create/distributor", payload, this.esHeaders());
	}

	saveSurveyDetail(payload): Observable<any> {
		return this.http.post(this.apiBaseUrl + "elastic/create/survey", payload, this.esHeaders());
	}

	sendUdyamLink(payload) : Observable<any> {
		return this.http.post(this.apiBaseUrl + `elastic/send_udyam_install_link`, payload, this.esHeaders())
	}


	saveCallRemark(payload): Observable<any> {
		return this.http.post(this.apiBaseUrl + "elastic/create/call_remarks", payload, this.esHeaders());
	}

	saveConcernRemark(payload): Observable<any> {
		return this.http.patch(this.apiBaseUrl + "elastic/store/remarks", payload, this.esHeaders());
	}

	postCurrentSteps(payload): Observable<any> {
		return this.http.post(this.apiBaseUrl + "elastic/insert/steps", payload, this.esHeaders());
	}

	getCurrentSteps(customer_code, partner_code, mobile): Observable<any> {
		return this.http.get(this.apiBaseUrl + `elastic/fetch_offers/step?customer_code=${customer_code}&partner_code=${partner_code}&mobile=${mobile}&offering_type=business_prime`, this.esHeaders());
	}

	getTask(customer_code): Observable<any> {
		return this.http.get(this.apiBaseUrl + `elastic/fetch_task/${customer_code}`, this.esHeaders());
	}

	createOffering(partner_code, customer_code, loan_uid, mobile_number): Observable<any> {
		const body = {
			partner_code: partner_code,
			customer_code: customer_code,
			loan_uid: loan_uid,
			mobile: mobile_number,
			offering_type: "business_prime",
		};
		return this.http.post(this.apiBaseUrl + "elastic/create/offering", body, this.esHeaders());
	}

	sendOfferLink(payload): Observable<any> {
		return this.http.post(this.apiBaseUrl + "elastic/send_offer_link", payload, this.esHeaders());
	}

	uploadFile(formData): Observable<any> {
		return this.http.post(this.apiBaseUrl + "elastic/sanction/uploads", formData, this.esHeaders());
	}
    getFetchGenerateLink(loanUid: any) {
		return this.http.get(this.apiBaseUrl + `fetch_payment_link?loan_uid=${loanUid}`, this.esHeaders());
	}
	sentFetchGenerateLink(payload: any) {
		return this.http.post(this.apiBaseUrl + "create_payment_link", payload, this.esHeaders());
	}
	getImage(path: string): Observable<any> {
		let headers = new HttpHeaders();
		/* if (environment.urls.elastic.auth !== "") {
			headers = headers.set('Authorization', environment.urls.elastic.auth);
		} */
		const accessToken: any = localStorage.getItem("accessToken");
		if (accessToken) {
			headers = headers.set("Token", `Bearer ${accessToken}`);
		}
		return this.http.get(path, { headers, responseType: "blob" });
	}

	getBase64Image(loan: string): Observable<any> {
		let headers = new HttpHeaders();
		/* if (environment.urls.elastic.auth !== "") {
			headers = headers.set('Authorization', environment.urls.elastic.auth);
		} */
		const accessToken: any = localStorage.getItem("accessToken");
		if (accessToken) {
			headers = headers.set("Token", `Bearer ${accessToken}`);
		}
		return this.http.get(`${this.apiBaseUrl}show_photo/${loan}`, { headers });
	}

	getLoans(): Observable<any> {
		/* return this.http.get(
			this.esBaseUrl + environment.urls.elastic.indices.loans + '/_search?' +
			'size=5' +
			'&sort=t:desc' +
			'&filter_path=' +
			'hits.hits._source.id,' +
			'hits.hits._source.applicant_id,' +
			'hits.hits._source.loan_uid,' +
			'hits.hits._source.amount,' +
			'hits.hits._source.approved_at,' +
			'hits.hits._source.disbursed_at,' +
			'hits.hits._source.due_at,' +
			'hits.hits._source.repayment_track,' +
			'hits.hits._source.closed_at,' +
			'hits.hits._source.amount_approved,' +
			'hits.hits._source.amount_offered,' +
			'hits.hits._source.amount_requested,' +
			'hits.hits._source.customer,' +
			'hits.hits._source.partner,' +
			'hits.hits._source.lender,' +
			'hits.hits._source.status,' +
			'hits.hits._source.t,' +
			'hits.total.value'
		); */
		let body = {
			index: environment.urls.elastic.indices.loans,
			body: {
				query: {
					bool: {
						must: [],
					},
				},
				sort: [],
				aggs: {
					total_count: {
						value_count: {
							field: "_id",
						},
					},
				},
				user: {},
			},
			_source: [
				"id",
				"applicant_id",
				"loan_uid",
				"amount",
				"approved_at",
				"disbursed_at",
				"due_at",
				"repayment_track",
				"closed_at",
				"amount_approved",
				"amount_offered",
				"amount_requested",
				"customer",
				"partner",
				"lender",
				"status",
				"t",
				// "total.value",
			],
			size: 5,
			from: 0,
			filterPath: [],
			sort: ["t:desc"],
		};
		return this.http.post(this.esBaseUrl + "search", body, this.esHeaders());
	}

	getLoanRepayMentsByLoanUID(loan_uid): Observable<any> {
		// return this.http.get(
		// 	this.esBaseUrl + environment.urls.elastic.indices.repayments + '/_search?' +
		// 	'size=1000' +
		// 	'&sort=t:desc' +
		// 	'&q=loan_uid:' + loan_uid
		// );
		let body = {
			index: environment.urls.elastic.indices.repayments,
			body: {
				query: {
					match_phrase: { loan_uid: loan_uid },
				},
				sort: [],
				aggs: {
					total_count: {
						value_count: {
							field: "_id",
						},
					},
				},
				user: {},
			},
			_source: [],
			size: 1000,
			from: 0,
			filterPath: [],
			sort: ["t:desc"],
		};
		return this.http.post(this.esBaseUrl + "search", body, this.esHeaders());
	}

	getLoanByCustomers(id): Observable<any> {
		/* return this.http.get(
			this.esBaseUrl + environment.urls.elastic.indices.loans + '/_search?' +
			'size=10000' +
			// tslint:disable-next-line:max-line-length
			'&filter_path=hits.hits._source.id,hits.hits._source.applicant_id,hits.hits._source.loan_uid,hits.hits._source.amount,hits.hits._source.approved_at,hits.hits._source.disbursed_at,hits.hits._source.due_at,hits.hits._source.repayment_track,hits.hits._source.closed_at,hits.hits._source.amount_approved,hits.hits._source.amount_offered, hits.hits._source.amount_requested,hits.hits._source.status,hits.hits._source.t,hits.total.value' +
			'&sort=t:desc' +
			'&q=applicant_id:' + id
		); */
		let body = {
			index: environment.urls.elastic.indices.loans,
			body: {
				query: {
					match_phrase: { applicant_id: id },
				},
				sort: [],
				aggs: {
					total_count: {
						value_count: {
							field: "_id",
						},
					},
				},
				user: {},
			},
			_source: [
				"id",
				"applicant_id",
				"loan_uid",
				"amount",
				"approved_at",
				"disbursed_at",
				"due_at",
				"repayment_track",
				"closed_at",
				"amount_approved",
				"amount_offered",
				"amount_requested",
				"status",
				"t",
				// "total.value",
			],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: ["t:desc"],
		};
		return this.http.post(this.esBaseUrl + "search", body, this.esHeaders());
	}

	getLoanRequestsByCustomers(id): Observable<any> {
		/* return this.http.get(
			this.esBaseUrl + environment.urls.elastic.indices.loan_requests + '/_search?' +
			'size=10000' +
			'&filter_path=hits.total.value,hits.hits._id,hits.hits._source.amount_approved,hits.hits._source.amount_requested,hits.hits._source.requested_at,hits.hits._source.status' +
			'&sort=t:desc' +
			'&q=applicant_id:' + id
		); */
		let body = {
			index: environment.urls.elastic.indices.loan_requests,
			body: {
				query: {
					match_phrase: { applicant_id: id },
				},
				sort: [],
				aggs: {
					total_count: {
						value_count: {
							field: "_id",
						},
					},
				},
				user: {},
			},
			_source: ["total.value", "_id", "amount_approved", "amount_requested", "requested_at", "status"],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: ["t:desc"],
		};
		return this.http.post(this.esBaseUrl + "search", body, this.esHeaders());
	}

	preClosure(preCloseData: any): Observable<any> {
		return this.http.post(this.apiBaseUrl + "loans/pre_close", preCloseData, this.esHeaders());
	}

	getBusinessPrime(customer_code: any): Observable<any> {
		return this.http.get(this.apiBaseUrl + "internal/business-prime/" + customer_code, this.esHeaders());
	}

	getLoanSoa(loanUid: string): Observable<any> {
		return this.http.get(this.apiBaseUrl + "internal/soa-pdf/" + loanUid, this.esHeaders());
	}

	getLoanCard(loanUid: string): Observable<any> {
		return this.http.get(this.apiBaseUrl + "internal/loan-card-pdf/" + loanUid, this.esHeaders());
	}

	getLoanNoc(loanUid: string): Observable<any> {
		return this.http.get(this.apiBaseUrl + "internal/noc-pdf/" + loanUid, this.esHeaders());
	}

	getWelcomeLetter(loanUid: string): Observable<any> {
		return this.http.get(this.apiBaseUrl + "welcome_letter_pdf/" + loanUid, this.esHeaders());
	}

	getLoanContract(loanUid: string): Observable<any> {
		return this.http.get(this.apiBaseUrl + "internal/tnc-pdf/" + loanUid, this.esHeaders());
	}

	sync(loanUid: string): Observable<any> {
		return this.http.get(this.apiBaseUrl + "sync/loan/" + loanUid, this.esHeaders());
	}

	verifyLoanStatus(kyc_payload: any): Observable<any> {
		return this.http.post(this.apiBaseUrl + "loans/update_status", kyc_payload, this.esHeaders());
	}

	resumeLoanStatus(kyc_payload: any): Observable<any> {
		return this.http.patch(this.apiBaseUrl + "elastic/revert/sanction_hold", kyc_payload, this.esHeaders());
	}

	enachDetails(loanUid: string): Observable<any> {
		return this.http.get(this.apiBaseUrl + "enach_details/" + loanUid, this.esHeaders());
	}

	changeLender(loan_uid: any, lender_code: string) {
		let body = {
			loan_uids: loan_uid,
			lender_code: lender_code,
		};
		return this.http.post(this.apiBaseUrl + "loans/change_lender", body, this.esHeaders()).pipe(catchError(this.handleError));
	}

	getAllLoan(url, search_text, filter, page) {
		let body = {
			search_text: search_text,
			filters: filter,
			page_number: page.page_number,
			per_page: page.per_page,
		};
		return this.http.post(this.apiBaseUrl + url, body, this.esHeaders());
	}

	getIMEI(customer_code, partner_code): Observable<any>{
		return this.http.get(this.apiBaseUrl + `fetch/branch_device/${partner_code}/${customer_code}`, this.esHeaders())
	}

	getPaymintDeviceList() {
		return this.http.get(this.apiBaseUrl + "/get_paymint_device", this.esHeaders());
	}

	updatePaymintDeviceList(payload) {
		return this.http.patch(this.apiBaseUrl + "/update_paymint_device", payload, this.esHeaders());
	}

	getBusinessImages(lead_id) {
		return this.http.get(this.iceUrl + "api/v1/get-business-detail/" + lead_id);
	}

	getCredit(partner_code, customer_code) {
		return this.http.get(`${this.iceUrl}api/v1/get-bureau-data/${partner_code}/${customer_code}/customer`);
	}
	getScore(partner_code, customer_code) {
		return this.http.get(`${this.apiBaseUrl}elastic/equifax_report/${partner_code}/${customer_code}`);
	}

	handleError(error) {
		return throwError(error.error.error.base[0].base || "Server Error");
	}

	approveByLender(loanuids) {
		let body = {
			loan_uids: loanuids,
		};
		return this.http.post(this.apiBaseUrl + "loans/disburse_approval", body, this.esHeaders());
	}

	downloadLenderCsv(loanuids, report_type) {
		let body = {
			loan_uids: loanuids,
			report_type: report_type,
		};
		return this.http.post(this.ovsUrl + "kinara/reports", body, this.esHeaders());
	}

	lenderOnHold(loanuid, lender_reason, email) {
		let body = {
			loan_uid: loanuid,
			lender_reason: lender_reason,
			email: email,
		};
		return this.http.post(this.apiBaseUrl + "on_hold_by_lender", body, this.esHeaders());
	}

	fetchCustomerLocation(partner_code, customer_code) {
		return this.http.get(this.apiBaseUrl + "elastic/customer_location/" + partner_code + "/" + customer_code, this.esHeaders());
	}

	getTnC(loanUid): Observable<any> {
		return this.http.get(this.apiBaseUrl + "/loan_tnc_pdf/" + loanUid, this.esHeaders());
	}

	invokeFldg(payload) {
		return this.http.post(this.apiBaseUrl + "invoke_fldg", payload, this.esHeaders());
	}

	updatePaidFldg(payload) {
		return this.http.patch(this.apiBaseUrl + "/fldg_payment", payload, this.esHeaders());
	}

	fetchFldg(loanuid) {
		return this.http.get(this.apiBaseUrl + `fetch_loan_fldg/${loanuid}`, this.esHeaders());
	}
	sendUpdateLink(payload: any) {
		return this.http.post(this.lmsUrl + "/profile_change/create", payload, this.esHeaders());
	}

	getUpdateProfileList(loanUid: any) {
		return this.http.get(this.lmsUrl + `profile_change/list?loan_uid=${loanUid}`);
	}
	getAdditionalUpdateProfileList(loanUid: any) {
		return this.http.get(this.lmsUrl + `additional_change_request/list?loan_uid=${loanUid}`);
	}
	sendOTP(payload: any) {
		return this.http.post(this.lmsUrl + "send_otp_additional_change_request", payload, this.esHeaders());
	}
	verifyOTP(payload: any) {
		return this.http.post(this.lmsUrl + "verify/otp", payload, this.esHeaders());
	}
	sendRaiseRequest(payload: any) {
		return this.http.post(this.lmsUrl + "additional_change_request/create", payload, this.esHeaders());
	}
	verifyPinCode(pinCode: any, loanUid: any): Observable<any> {
		return this.http.get(this.lmsUrl + `pincode?pincode=${pinCode}&loan_uid=${loanUid}`, this.esHeaders());
	}

	getUpdateProfileDetail(id: any, loanUid: any) {
		return this.http.get(this.lmsUrl + `profile_change/list?loan_uid=${loanUid}&id=${id}`);
	}

	getAdditionalUpdateProfileDetail(id: any, loanUid: any) {
		return this.http.get(this.lmsUrl + `additional_change_request/list?loan_uid=${loanUid}&id=${id}`);
	}

	updateCustomerProfile(payload) {
		return this.http.post(this.lmsUrl + "profile_change/update_action", payload);
	}

	updatePhotoVideo(payload) {
		return this.http.post("https://lms-api.arthapp.in/" + "update_repeat_topup_data", payload);
	}

	updateAdditionalCustomerProfile(payload) {
		return this.http.post(this.lmsUrl + "additional_change_request/update_action", payload);
	}
    updatekyconhold(payload) {
		return this.http.post(this.apiBaseUrl + "loans/update_qc_stage", payload);
	}
	esHeaders() {
		if (environment.urls.elastic.bypass_es_auth) {
			return {};
		}
		let headers = new HttpHeaders();
		/* if (environment.urls.elastic.auth !== "") {
			headers = headers.set('Authorization', environment.urls.elastic.auth);
		} */
		let accessToken: any = localStorage.getItem("accessToken");
		if (accessToken) {
			headers = headers.set("Token", `Bearer ${accessToken}`);
		}
		return { headers };
	}
}